import React, { useCallback, useContext, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Image,
  Text,
  VStack,
  BoxProps,
  HStack,
  Drawer,
  DrawerContent,
  IconButton,
  useDisclosure,
  Icon,
  FlexProps,
  CloseButton,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
import Link from '../Atoms/CustomLink';
import discordSVG from '../../images/discord_icon_clyde_white_RGB.svg';
import { IconType } from 'react-icons';
import { FiMenu } from 'react-icons/fi';
import { AppContext } from './NavbarWrapper';
import { signOut } from '../../utils/auth-helper';
import { H1Text, H2Text } from '../../components/Atoms/TextComponents';
import { getPlanStorage, getPlanString } from '../../utils/stripeUtils';
import Tappable from 'react-tappable';
import { CustomOnTap } from '../../components/Atoms/CustomOnTap';

interface NavItemProps extends FlexProps {
  page?: string;
  isExternal?: boolean;
  icon?: IconType;
  img?: any;
  onClose?: () => void;
  action?: () => void;
  children: React.ReactText;
}

const GuestLinkItems = ({ onClose, studioDispatch }) => {
  return (
    <>
      <NavItem onClose={onClose} page={'/'}>
        Home
      </NavItem>
      <NavItem onClose={onClose} page={'/studio/'}>
        Studio
      </NavItem>
      <NavItem onClose={onClose} page={'/guide/'}>
        Guide
      </NavItem>
      {/* <NavItem onClose={onClose} page={'/API/'}>
        API
      </NavItem> */}
      {/* <NavItem onClose={onClose} page={'/pricing/'}>
        Pricing
      </NavItem> */}
      <NavItem onClose={onClose} page={'/legal/privacy-policy/'}>
        Privacy Policy
      </NavItem>
      <NavItem
        onClose={onClose}
        action={() => {
          studioDispatch({
            type: 'SET_LOGIN_MODAL_OPEN',
            isOpen: true,
          });
        }}
      >
        Register / Log in
      </NavItem>
      <NavItem
        onClose={onClose}
        isExternal
        page={'https://discord.gg/hH2kMtvv8j'}
        img={discordSVG}
      >
        Discord
      </NavItem>
    </>
  );
};

const UserLinkItems = ({ onClose, setAuthUser }) => {
  return (
    <VStack align={'stretch'} spacing={'0'}>
      <NavItem onClose={onClose} page={'/'}>
        Home
      </NavItem>
      <NavItem onClose={onClose} page={'/studio/'}>
        Studio
      </NavItem>
      <NavItem onClose={onClose} page={'/guide/'}>
        Guide
      </NavItem>
      {/* <NavItem onClose={onClose} page={'/pricing/'}>
        Upgrade
      </NavItem> */}
      <NavItem onClose={onClose} page={'/API/'}>
        API
      </NavItem>
      <NavItem onClose={onClose} page={'/gallery/'}>
        My Gallery
      </NavItem>
      <NavItem onClose={onClose} page={'/account/'}>
        Account Settings
      </NavItem>
      <NavItem onClose={onClose} page={'/legal/privacy-policy/'}>
        Privacy Policy
      </NavItem>
      <NavItem
        onClose={onClose}
        action={async () => {
          await signOut();
          setAuthUser(null);
        }}
      >
        Logout
      </NavItem>
      <NavItem
        onClose={onClose}
        isExternal
        page={'https://discord.gg/hH2kMtvv8j'}
        img={discordSVG}
      >
        Discord
      </NavItem>
    </VStack>
  );
};

export const NavbarMobile = ({ ...props }) => {
  const { user, authUser, setAuthUser, studioDispatch } =
    useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    console.log(`isOpen: ${isOpen}`);
  }, [isOpen]);
  return (
    <>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        returnFocusOnClose={false}
        // onOverlayClick={onClose}
        size="full"
        colorScheme="blue"
        onClose={null}
      >
        <DrawerContent bg={'gray.900'}>
          {/* <GuideSidebarContent onClose={onClose} /> */}

          <DrawerBody bg={'gray.900'}>
            <NavMobileMenuContent
              title=""
              onClose={() => {
                console.log(`drawer on close`);
                onClose();
              }}
              // items=
            >
              {authUser ? (
                <VStack align={'stretch'}>
                  <Center>
                    <VStack>
                      <Avatar src={authUser.attributes.picture} />
                      <H1Text>{authUser.attributes.name}</H1Text>
                      <H2Text>Current plan: {getPlanString(user)}</H2Text>
                      <H2Text>
                        Pro Credits:{' '}
                        {user?.plan === 'free'
                          ? user?.expiringCredits + user?.nonExpiringCredits
                          : '∞'}
                      </H2Text>
                    </VStack>
                  </Center>
                  <UserLinkItems setAuthUser={setAuthUser} onClose={onClose} />
                  <Box minH={'300px'} />
                </VStack>
              ) : (
                <VStack align={'stretch'}>
                  <GuestLinkItems
                    studioDispatch={studioDispatch}
                    onClose={onClose}
                  />
                  <Box minH={'300px'} />
                </VStack>
              )}
            </NavMobileMenuContent>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box display={['flex', 'flex', 'none', 'none']}>
        <CustomOnTap onTap={onOpen}>
          <IconButton
            variant="outline"
            // onClick={onOpen}
            aria-label="open menu"
            icon={<FiMenu />}
          />
        </CustomOnTap>
      </Box>
    </>
  );
};

interface NavMobileMenuContentProps extends BoxProps {
  onClose: () => void;
  title: string;
  // items: any[];
}

export const NavMobileMenuContent = ({
  onClose,
  title,
  children,
  ...rest
}: NavMobileMenuContentProps) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.900')}
      w={'full'}
      h="full"
      minH={'700px'}
      style={{ zIndex: 5 }}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          {title}
        </Text>
        <CloseButton onClick={onClose} />
      </Flex>
      {children}
    </Stack>
  );
};

const NavItem = ({
  icon,
  img,
  page,
  isExternal,
  action,
  children,
  onClose,
  ...rest
}: NavItemProps) => {
  return (
    <Link
      isExternal={isExternal}
      href={page ?? ''}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={() => {
        console.log(`clicked link`);
        action && action();
        onClose && onClose();
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {img && <Image src={img} boxSize={'30px'} pr={'10px'} />}
        {children}
      </Flex>
    </Link>
  );
};
