import { useContext, useEffect, useState } from 'react';
import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Image,
  Text,
  VStack,
  BoxProps,
  HStack,
  Drawer,
  DrawerContent,
  IconButton,
  useDisclosure,
  Icon,
  FlexProps,
  CloseButton,
} from '@chakra-ui/react';
import Link from '../Atoms/CustomLink';
import logoImgLight from '../../images/reDreamLogo_light.svg';
import { navigate } from 'gatsby';
import {
  getAuthenticated,
  getGuestCredentials,
  signOut,
} from '../../utils/auth-helper';
import NavbarMenuList from './NavbarMenuList';
import { getUserByIdHelper } from '../../utils/api-helper';
import { User } from 'types/redreamTypes';
import { AppContext } from './NavbarWrapper';
import discordSVG from '../../images/discord_icon_clyde_white_RGB.svg';
import { FiMenu } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { NavbarMobile } from './NavbarMobile';

const UpgradePlanButton = ({ title = 'Upgrade Plan', ...props }) => {
  return (
    <Button
      size="sm"
      mt={5}
      borderRadius={25}
      bgGradient="linear(to-br, #2272FF, #0D03C3)"
      variant="solid"
      color="white"
      onClick={() => {
        navigate('/pricing');
      }}
      _hover={{ bgGradient: 'linear(to-br, #0D03C3, #0000FF)' }}
      _active={{ bgGradient: 'linear(to-br, #0000AF, #0000AF)' }}
    >
      {title}
    </Button>
  );
};

interface NavProps extends BoxProps {
  onAuthComplete?: (user: any) => void;
  onGetUserComplete?: (user: User) => void;
}

export const NavbarDesktop = ({ ...props }) => {
  const { user, setUser, authUser, setAuthUser, studioState, studioDispatch } =
    useContext(AppContext);

  const selectProps = (pageName: string) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const isSelected = (page: string) => {
      console.log(`page: ${page} selected: ${url.split('/').slice(3)}`);
      return page === url.split('/').slice(3)[0];
    };

    return { as: (isSelected(pageName) ? 'u' : '') as any };
  };

  return (
    <Flex alignItems={'center'} display={['none', 'none', 'flex', 'flex']}>
      <Stack
        direction={'row'}
        spacing={7}
        alignItems={'center'}
        style={{ zIndex: 5 }}
      >
        <Link ml={10} to="/studio">
          <Text {...selectProps('studio')}>Studio</Text>
        </Link>

        <Link ml={0} to="/guide">
          <Text {...selectProps('guide')}>Guide</Text>
        </Link>

        {/* <Link ml={0} to="/API">
          <Text {...selectProps('API')}>API</Text>
        </Link> */}

        <Link isExternal href="https://discord.gg/hH2kMtvv8j">
          <Image src={discordSVG} boxSize={'25px'} />
        </Link>

        {authUser ? (
          <>
            {/* <UpgradePlanButton
              title={
                user?.plan === 'free'
                  ? `Pro Credits: ${
                      user.expiringCredits + user.nonExpiringCredits
                    }`
                  : `Upgrade Plan`
              }
            /> */}
            {/* <HStack>
              <Text>Credits:</Text>
              <Text fontWeight={'bold'}>
                {user.expiringCredits + user.nonExpiringCredits}
              </Text>
            </HStack> */}
            <NavbarMenuList
              user={user}
              onSignOut={() => {
                setAuthUser(null);
              }}
            />
          </>
        ) : (
          <>
            {/* <UpgradePlanButton title={'See Pricing'} /> */}
            <Text
              style={{
                cursor: 'pointer',
              }}
              _hover={{ textDecoration: 'underline' }}
              onClick={() => {
                studioDispatch({
                  type: 'SET_LOGIN_MODAL_OPEN',
                  isOpen: true,
                });
              }}
            >
              Register / Log in
            </Text>
          </>
        )}
      </Stack>
    </Flex>
  );
};
